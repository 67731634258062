<template>
  <navbar></navbar>
  <section class="section box container">
    <h1 class="title has-text-left">User Profile</h1>

    <div class="box container">
      <h1 class="subtitle">Edit Personal Information</h1>

      <!-- Edit Form -->
      <div class="field">
        <label class="label">First Name</label>
        <div class="control">
          <input
            class="input"
            v-model="profile.first_name"
            type="text"
            placeholder="First Name"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Last Name</label>
        <div class="control">
          <input
            class="input"
            v-model="profile.last_name"
            type="text"
            placeholder="Last Name"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input
            class="input"
            v-model="profile.email"
            type="email"
            placeholder="Email"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Contact Number</label>
        <div class="control">
          <input
            class="input"
            v-model="profile.contact_number"
            type="text"
            placeholder="Contact Number"
          />
        </div>
      </div>

      <!-- Company Logo Upload -->
      <div class="field" v-if="profile.role == 'ACCOUNT_ADMIN'">
        <label class="label">Company Logo</label>
        <div v-if="profile.customer_logo">
          <figure class="image is-64x64" v-if="profile.customer_logo != 'URL'">
            <img :src="profile.customer_logo" alt="Customer Logo" />
          </figure>
        </div>
        <div class="file is-info is-rounded">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              @change="onImageChange"
              accept="image/*"
            />
            <!-- <input class="file-input" type="file" @change="uploadLogo" /> -->
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span v-if="!imageFile" class="file-label">Choose a logo...</span>
              <span v-else class="file-label"> {{ imageFile.name }}</span>
            </span>
          </label>
        </div>
      </div>

      <!-- Update Button -->
      <div class="field is-pulled-right mb-5">
        <div class="control">
          <button
            :disabled="loading"
            class="button is-success is-rounded"
            @click="updateProfile"
          >
            <span v-if="!loading">Update Profile</span>
            <span v-else class="fas fa-spinner fa-spin"></span>
            <!-- Loading spinner -->
          </button>
        </div>
      </div>
    </div>
  </section>
  <Footer></Footer>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { useStore } from "vuex";
import { db, storage } from "@/firebase/config";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { doc, setDoc, updateDoc } from "firebase/firestore";

export default {
  components: { Navbar, Footer },
  name: "UserProfile",
  setup() {
    const store = useStore();
    const userProfile = computed(() => store.state.userProfile);
    const user = computed(() => store.state.user);

    const loading = ref(false); // Loading state for updateProfile

    // Assuming you'll fetch the initial profile data from an API or a store
    const profile = ref({
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      customer_logo: null,
    });
    const imageFile = ref(null);

    const onImageChange = (event) => {
      imageFile.value = event.target.files[0];
    };

    watchEffect(() => {
      if (userProfile.value) {
        profile.value = userProfile.value;
      }
    });

    const updateProfile = async () => {
      loading.value = true; // Set loading to true at the beginning of the request
      try {
        if (imageFile.value) {
          const fileRef = storageRef(
            storage,
            `company-logos/${profile.value.primary_account}/`
          );
          await uploadBytes(fileRef, imageFile.value)
            .then((snapshot) => {
              return getDownloadURL(snapshot.ref);
            })
            .then((downloadUrl) => {
              profile.value.customer_logo = downloadUrl;
            });
        }

        //console.log(user.value.uid, profile.value);
        const docRef = doc(db, "Users", user.value.uid);
        const primaryAccountRef = doc(
          db,
          "Logos",
          profile.value.primary_account
        );
        await setDoc(primaryAccountRef, { url: profile.value.customer_logo });
        await updateDoc(docRef, profile.value);
      } catch (error) {
        console.error("Error updating profile:", error);
      } finally {
        loading.value = false; // Set loading to false at the end of the request
        imageFile.value = null;
      }
    };

    return {
      profile,
      updateProfile,
      onImageChange,
      imageFile,
      loading, // Add loading to the returned object
    };
  },
};
</script>

<style>
/* You can add specific styles for this view if needed */
</style>
