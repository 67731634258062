<template>
  <Navbar></Navbar>

  <section class="section box container">
    <router-link to="/supplier/dashboard">
      <button class="button is-rounded is-small mb-5">
        <span class="material-icons">arrow_back_ios</span> Back
      </button>
    </router-link>

    <h1 class="title has-text-left">All Transactions</h1>

    <div class="columns is-centered">
      <!-- Date Selection -->
      <div class="column is-narrow">
        <label class="label">Start Date:</label>
        <input type="date" v-model="startDate" class="input" />
      </div>
      <div class="column is-narrow">
        <label class="label">End Date:</label>
        <input type="date" v-model="endDate" class="input" />
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column box container uniform-card">
        <p class="is-size-4">Volume by Month</p>
        <p>UCO Collection trend over months</p>
        <BarChart
          :chart-data="chartData"
          :options="chartOptions"
          :key="chartKey"
        />
      </div>
    </div>

    <!-- Transactions Table -->
    <div class="box conatiner">
      <h1 class="is-size-4 mb-5">Transaction Data</h1>
      <Vue3EasyDataTable
        v-if="transactions.length"
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="transactions"
      >
        <template #item-DateTimeOfCollection="{ DateTimeOfCollection }">
          {{ formatDate(DateTimeOfCollection) }}
        </template>
      </Vue3EasyDataTable>
    </div>
  </section>

  <Footer></Footer>
</template>

<script>
import { useStore } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import BarChart from "@/components/BarChart.vue";
import { onMounted, ref, toRef, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "AllTransactionsSupplier",
  props: ["group_id"],
  components: { Navbar, Footer, Vue3EasyDataTable, BarChart },
  setup(props) {
    const route = useRoute();
    const group_id = ref(route.params.group_id);
    const transactions = ref([]);
    const endDate = ref(new Date().toISOString().substr(0, 10));
    const startDate = ref(
      new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .substr(0, 10)
    );
    const chartKey = ref(0);
    const store = useStore();

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      return date.toLocaleDateString();
    };

    const headers = [
      { value: "DateTimeOfCollection", text: "Date", sortable: true },
      { value: "PurchaseOrderNumber", text: "PO Number", sortable: true },
      { value: "SupplierName", text: "Supplier Name", sortable: true },
      { value: "LocationName", text: "Location Name", sortable: true },
      { value: "Quantity", text: "Volume (L)", sortable: true },
      { value: "Comments", text: "Comments", sortable: true },
    ];

    const fetchTransactions = async () => {
      const apiUrl = store.state.apiUrl + "getAllSupplierCollectionsByGroup";
      //   console.log(group_id);
      try {
        const response = await fetch(
          `${apiUrl}/${group_id.value}?start_date=${startDate.value}&end_date=${endDate.value}`
        );
        if (response.ok) {
          const result = await response.json();
          transactions.value = result[1].collectionData;
          console.log(result);
        } else {
          console.error("Error fetching transactions");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    watch([startDate, endDate], () => {
      fetchTransactions();
    });

    const chartData = ref({
      labels: [],
      datasets: [
        {
          label: "Total Volume",
          backgroundColor: "#00d4ff",
          data: [],
        },
      ],
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    watch(transactions, (newTransactions) => {
      const groupedData = {};

      newTransactions.forEach((transaction) => {
        const date = new Date(transaction.DateTimeOfCollection);
        const monthName = `${date.toLocaleString("default", {
          month: "long",
        })} ${date.getFullYear()}`;

        if (!groupedData[monthName]) groupedData[monthName] = 0;

        groupedData[monthName] += transaction.Quantity;
      });

      chartData.value.labels = Object.keys(groupedData);
      chartData.value.datasets[0].data = Object.values(groupedData);

      chartKey.value++;
    });

    onMounted(fetchTransactions);

    return {
      startDate,
      endDate,
      headers,
      formatDate,
      transactions,
      chartData,
      chartOptions,
      chartKey,
    };
  },
};
</script>

<style>
</style>