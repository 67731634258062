<template>
  <navbar></navbar>
  <section class="section" v-if="isLoading">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
  </section>

  <section class="section box container tree-cards" v-if="!isLoading">
    <div v-if="userProfile">
      <div
        class="container is-flex is-align-items-center is-justify-content-space-between"
      >
        <h1 class="title">Dashboard</h1>
        <figure class="image is-96x96">
          <img
            v-if="userProfile.customer_logo != 'URL'"
            :src="userProfile.customer_logo"
            alt="Customer Logo"
          />
        </figure>
      </div>

      <div class="container">
        <div class="columns is-centered">
          <div class="column is-narrow">
            <label class="label">Start Date:</label>
            <input type="date" v-model="startDate" class="input" />
          </div>
          <div class="column is-narrow">
            <label class="label">End Date:</label>
            <input type="date" v-model="endDate" class="input" />
          </div>
        </div>
      </div>

      <div class="cards-section">
        <div
          class="container box"
          style="margin: 1%"
          v-for="(group, index) in userProfile.group"
          :key="index"
        >
          <h1 class="title has-text-left">
            {{ group.group_name }} - {{ group.site_name }}
          </h1>

          <div
            class="columns is-multiline"
            v-if="!isLoading && transtactions.length"
          >
            <!-- <div class="column is-one-fifth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{ transtactions[index][0].NoOfActiveLocations }}
                  </p>
                  <p class="is-size-6">No. of Active Locations</p>
                </div>
              </div>
            </div> -->

            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{ transtactions[index][0].NoOfActiveSuppliers }}
                  </p>
                  <p class="is-size-6">No. of Active Suppliers</p>
                </div>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{ transtactions[index][0].NoOfUCOCOllections }}
                  </p>
                  <p class="is-size-6">No. of UCO Collections</p>
                </div>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{ transtactions[index][0].TotalUCOCollectedFormatted }}
                  </p>
                  <p class="is-size-6">Total UCO Collected<br />(L)</p>
                </div>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{ transtactions[index][0].FacilitatedSavingsFormatted }}
                  </p>
                  <p class="is-size-6">Facilitated Savings<br />(KgCo2eq)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="sction box container" v-if="!isLoading">
    <div v-if="userProfile">
      <div
        class="container is-flex is-justify-content-space-between is-align-items-center"
      >
        <h1 class="title">Collection Summary</h1>

        <div class="buttons are-small">
          <router-link
            :to="{
              name: 'AllTransactionsSupplier',

              params: {
                group_id: userProfile.group[transactionsIndex].group_id,
              },
            }"
          >
            <button class="button is-rounded">
              <span class="icon is-small">
                <i class="material-icons">visibility</i>
                <!-- Material icon for 'View' -->
              </span>
              <span class="ml-1">View All Transactions</span>
            </button>
          </router-link>
          <!--
          <button
            class="button is-info is-rounded ml-3"
            @click="exportCardsToPDF"
          >
            <span class="icon is-small">
              <i class="material-icons">download</i>
              
            </span>
            <span>Download Accounts Summary</span>
          </button>-->
          <button
            class="button is-link is-rounded ml-3"
            @click="exportSummaryToCSV"
            disabled
          >
            <span class="icon is-small">
              <i class="material-icons">import_export</i>
              <!-- Material icon for 'Export' -->
            </span>
            <span>Export Collection Summary (.csv)</span>
          </button>
          <button
            class="button is-link is-rounded"
            @click="fetchAllTransactions"
            disabled
          >
            <span class="icon is-small">
              <i class="material-icons">import_export</i>
              <!-- Material icon for 'Export' -->
            </span>
            <span>Export All Collections (.csv)</span>
          </button>
        </div>
      </div>

      <div class="select is-rounded mb-5">
        <select name="Select Account" id="" v-model="transactionsIndex">
          <option disabled value="">Select an account</option>
          <option
            :value="index"
            v-for="(customer, index) in userProfile.group"
            :key="index"
          >
            {{ customer.group_name }} - {{ customer.site_name }}
          </option>
        </select>
      </div>

      <Vue3EasyDataTable
        v-if="
          transactionSummary[transactionsIndex] &&
          transactionSummary[transactionsIndex].length > 0
        "
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="transactionSummary[transactionsIndex]"
      ></Vue3EasyDataTable>
    </div>
  </section>
  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
export default {
  components: { Navbar, Loading, Footer, Vue3EasyDataTable },
  setup() {
    const isLoading = ref(false);
    const store = useStore();

    const startDate = ref(
      new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .substr(0, 10)
    );
    const endDate = ref(new Date().toISOString().substr(0, 10));

    const transactionsIndex = ref(0);
    const transtactions = ref([]);
    const transactionSummary = ref([]);

    const userProfile = computed(() => store.state.userProfile);
    const groupIds = ref([]);

    const headers = [
      { value: "SupplierName", text: "Supplier Name", sortable: true },
      {
        value: "LocationName",
        text: "Location Name",
        sortable: true,
      },
      {
        value: "TotalUCOCollectedFormatted",
        text: "Total UCO Collected (L)",
        sortable: true,
      },
      {
        value: "FacilitatedSavingsFormatted",
        text: "Facilitated Savings (KgCo2eq)",
        sortable: true,
      },
    ];

    const formatNumber = (num) => {
      return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatNumberWithSuffix = (num) => {
      if (num >= 1000000) return (num / 1000000).toFixed(2) + " Mil";
      else if (num >= 1000) return (num / 1000).toFixed(2) + " K";
      return num.toFixed(2);
    };

    const fetchTransactions = async (group_ids) => {
      const isLoading = ref(true);
      const apiUrl = store.state.apiUrl + "getGroupCollectionSummary";
      const apiUrl2 =
        store.state.apiUrl + "getSupplierCollectionSummaryByGroup";
      try {
        for (let i = 0; i < group_ids.length; i++) {
          const group_id = group_ids[i];

          const response = await fetch(
            `${apiUrl}/${group_id}?start_date=${startDate.value}&end_date=${endDate.value}`
          );

          const response2 = await fetch(
            `${apiUrl2}/${group_id}?start_date=${startDate.value}&end_date=${endDate.value}`
          );

          if (response.ok) {
            const result = await response.json();
            result[1].groupSummary.forEach((summary) => {
              if (summary.TotalUCOCollected !== undefined) {
                // Calculate FacilitatedSavings and add it to the summary object
                summary.FacilitatedSavings =
                  summary.TotalUCOCollected * 0.94 * 3.00403377;
              }
              if (summary.TotalUCOCollected !== undefined) {
                summary.TotalUCOCollectedFormatted = formatNumberWithSuffix(
                  summary.TotalUCOCollected
                );
              }
              if (summary.FacilitatedSavings !== undefined) {
                summary.FacilitatedSavingsFormatted = formatNumberWithSuffix(
                  summary.FacilitatedSavings
                );
              }
            });

            transtactions.value[i] = result[1].groupSummary;
            console.log(transtactions.value[i]);
          } else {
            console.error("Error fecthing collection summary");
          }

          if (response2.ok) {
            const result2 = await response2.json();
            result2[1].groupSummary.forEach((summary) => {
              if (summary.TotalUCOCollected !== undefined) {
                // Calculate FacilitatedSavings and add it to the summary object
                summary.FacilitatedSavings =
                  summary.TotalUCOCollected * 0.94 * 3.00403377;
              }
              if (summary.TotalUCOCollected !== undefined) {
                summary.TotalUCOCollectedFormatted = formatNumber(
                  summary.TotalUCOCollected
                );
              }
              if (summary.FacilitatedSavings !== undefined) {
                summary.FacilitatedSavingsFormatted = formatNumber(
                  summary.FacilitatedSavings
                );
              }
            });

            transactionSummary.value[i] = result2[1].groupSummary;

            console.log(transactionSummary.value[i]);
          } else {
            console.error("Error fecthing collection summary");
          }
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        isLoading.value = false;
      }
    };

    watchEffect(() => {
      if (userProfile.value) {
        let group_Ids = [];
        for (let i = 0; i < userProfile.value.group.length; i++) {
          group_Ids.push(userProfile.value.group[i].group_id);
        }
        groupIds.value = group_Ids;
        fetchTransactions(group_Ids);
      }
    });

    watch([startDate, endDate], () => fetchTransactions(groupIds.value));

    return {
      isLoading,
      userProfile,
      startDate,
      endDate,
      transtactions,
      transactionsIndex,
      transactionSummary,
      headers,
    };
  },
};
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.uniform-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.uniform-card .card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dynamic-font-size {
  font-size: calc(1rem + 0.8vw);
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-cards {
  background-image: url("../../assets/sky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.customize-table {
  --easy-table-border: 1px solid #c1cad4;
  --easy-table-row-border: 1px solid #c1cad4;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: rgb(20, 122, 190);

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #c1cad4;
  --easy-table-body-row-hover-background-color: rgb(20, 122, 190);

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;

  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

/* Round the table borders */
.vue3-easy-data-table {
  border-radius: 20px;
  overflow: hidden; /* This ensures the inner content respects the border-radius */
}

/* Additionally, if the table has a border, round its top-left and top-right corners */
.vue3-easy-data-table thead th:first-child {
  border-top-left-radius: 20px;
}

.vue3-easy-data-table thead th:last-child {
  border-top-right-radius: 20px;
}
</style>