<template>
  <Navbar></Navbar>
  <section class="section box container">
    <router-link to="/">
      <button class="button is-rounded is-small mb-5">
        <span class="material-icons">arrow_back_ios</span> Back
      </button>
    </router-link>

    <h1 class="title has-text-left">All Transactions</h1>

    <div class="columns is-centered">
      <!-- Date Selection -->
      <div class="column is-narrow">
        <label class="label">Start Date:</label>
        <input type="date" v-model="startDate" class="input" />
      </div>
      <div class="column is-narrow">
        <label class="label">End Date:</label>
        <input type="date" v-model="endDate" class="input" />
      </div>
    </div>

    <!-- Add this right above the Transactions Table -->

    <div class="columns is-multiline">
      <div class="column box container uniform-card">
        <p class="is-size-4">Volume by Truck</p>
        <p>Fuel consumption for each truck</p>
        <BarChart
          :chart-data="chartData"
          :options="chartOptions"
          :key="chartKey"
        />
      </div>
      <div class="column box container uniform-card">
        <p class="is-size-4">Monthly Volume</p>
        <p>Fuel consumption trend over months</p>
        <BarChart
          :chart-data="chartData2"
          :options="chartOptions"
          :key="chartKey"
        />
      </div>
    </div>

    <!-- Transactions Table -->
    <div class="box conatiner">
      <h1 class="is-size-4 mb-5">Transaction Data</h1>
      <Vue3EasyDataTable
        v-if="transactions.length"
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="transactions"
      >
        <template #item-SaleDate="{ SaleDate }">
          {{ formatDate(SaleDate) }}
        </template>
      </Vue3EasyDataTable>
    </div>
  </section>
  <Footer></Footer>
</template>
  
  <script>
import { ref, onMounted, toRefs, watch } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import BarChart from "@/components/BarChart.vue";
import { useStore } from "vuex";

export default {
  name: "AllTransactions",
  props: ["customer_id"],
  components: { Vue3EasyDataTable, Navbar, Footer, BarChart },
  setup(props) {
    const { customer_id } = toRefs(props);
    const transactions = ref([]);
    const endDate = ref(new Date().toISOString().substr(0, 10));
    const startDate = ref(
      new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .substr(0, 10)
    );
    const chartKey = ref(0);
    const store = useStore();

    const headers = [
      { value: "SaleDate", text: "Sale Date", sortable: true },
      { value: "FuelType", text: "Fuel Type", sortable: true },
      { value: "TruckNumber", text: "Truck Number", sortable: true },
      {
        value: "OdometerReading",
        text: "Odometer Reading (Km)",
        sortable: true,
      },
      { value: "Quantity", text: "Volume (L)", sortable: true },
    ];

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      return date.toLocaleString();
    };

    const fetchTransactions = async () => {
      const apiUrl = store.state.apiUrl + "getCustomerTransactions";

      try {
        const response = await fetch(
          `${apiUrl}/${customer_id.value}?start_date=${startDate.value}&end_date=${endDate.value}`
        );

        if (response.ok) {
          const result = await response.json();
          transactions.value = result[1].transactionsData;
          // //console.log(transactions.value);
        } else {
          console.error("Error fetching transactions");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    watch([startDate, endDate], () => fetchTransactions());

    // Chart data and options
    const chartData = ref({
      labels: [],
      datasets: [
        {
          label: "Total Volume",
          backgroundColor: "#00d4ff",
          data: [],
        },
      ],
    });

    const chartData2 = ref({
      labels: [],
      datasets: [
        {
          label: "Total Volume",
          backgroundColor: "#020024",
          data: [],
        },
      ],
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    // Watch the transactions and update the chart data
    watch(transactions, (newTransactions) => {
      const groupedData = {}; // This will group the data by Truck Number
      const groupedData2 = {}; // This will group the data by date or month

      newTransactions.forEach((transaction) => {
        const truck = transaction.TruckNumber;
        const date = new Date(transaction.SaleDate);
        const monthName = `${date.toLocaleString("default", {
          month: "long",
        })} ${date.getFullYear()}`;

        if (!groupedData[truck]) groupedData[truck] = 0;
        if (!groupedData2[monthName]) groupedData2[monthName] = 0;

        groupedData[truck] += transaction.Quantity;
        groupedData2[monthName] += transaction.Quantity;
      });

      chartData.value.labels = Object.keys(groupedData);
      chartData.value.datasets[0].data = Object.values(groupedData);

      chartData2.value.labels = Object.keys(groupedData2);
      chartData2.value.datasets[0].data = Object.values(groupedData2);
      chartKey.value++;
    });

    onMounted(fetchTransactions);

    return {
      transactions,
      startDate,
      endDate,
      headers,
      formatDate,
      chartData,
      chartOptions,
      chartKey,
      chartData2,
    };
  },
};
</script>
  
  <style>
.uniform-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 1%;
}
</style>
  